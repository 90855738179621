html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  height: 100vh;

  margin: 0;
  padding: 0;

  font-family: system-ui;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: normal;

  color: var(--text-color);
  background-color: var(--light);
}

img {
  max-width: 100%;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

button,
a {
  -webkit-tap-highlight-color: transparent;
}

form {
  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
  }
}

:root {
  --module: #{$module};

  --weight-400: #{$weight-400};
  --weight-500: #{$weight-500};
  --weight-600: #{$weight-600};
  --weight-700: #{$weight-700};
  --weight-800: #{$weight-800};

  --border-radius-base: #{$border-radius-base};
  --border-radius-sm: #{$border-radius-sm};
  --border-radius-md: #{$border-radius-md};
  --border-radius-xl: #{$border-radius-xl};

  --transition-time-base: #{$transition-time-base};
  --transition-time-fast: #{$transition-time-fast};

  --height-action: #{$height-action};
  --height-button-base: #{$height-button-base};
  --height-content-refresh: #{$height-content-refresh};
  --height-header: #{$height-header};
  --height-header-with-space: #{$height-header-with-space};
  --height-input-base: #{$height-input-base};
  --height-logo: #{$height-logo};
  --height-navigation: #{$height-navigation};
  --height-message-reply: #{$height-message-reply};
  --height-suggest-option: #{$height-suggest-option};
  --height-tabs: #{$height-tabs};

  --safe-area-inset-top: #{$safe-area-inset-top};
  --safe-area-inset-bottom: #{$safe-area-inset-bottom};
}

.height-full {
  height: 100%;
}

.width-full {
  width: 100%;
}

$fadeIn: "fadeIn";
$fadeOut: "fadeOut";

@mixin fade-keyframes($names) {
    $opacity-min: 0;
    $opacity-max: 1;
    $transform-value: 1.25rem;
  
    @each $name in $names {
      @if $name == $fadeIn {
        $opacity-min: 0;
        $opacity-max: 1;
      }
  
      @if $name == $fadeOut {
        $opacity-min: 1;
        $opacity-max: 0;
      }
  
      @keyframes #{$name} {
        0% { opacity: $opacity-min; }
        100% { opacity: $opacity-max; }
      }
    }
}
  
  @mixin animations($animation-name, $keyframes-names) {
    .#{$animation-name} {
      animation-duration: var(--transition-time-base);
      animation-fill-mode: both;
    }
  
    @each $keyframe-name in $keyframes-names {
      .#{$animation-name}.#{$keyframe-name} {
        animation-name: #{$keyframe-name};
      }
    }
}

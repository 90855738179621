@import '../mixins/spacing';

@include make-spacings();

// Some special margins
.m-auto { margin: auto; }

.mt-auto,
.my-auto {
  margin-top: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.mb-auto,
.my-auto {
  margin-bottom: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

// Some special positions
.at-full { top: 100%; }
.at-half { top: 50%; }

.ar-full { right: 100%; }
.ar-half { right: 50%; }

.ab-full { bottom: 100%; }
.ab-half { bottom: 50%; }

.al-full { left: 100%; }
.al-half { left: 50%; }

.ab-0-ios { bottom: var(--safe-area-inset-bottom); }

// Transformation
.trf-ny-half {
  transform: translateY(-50%);
}

.trf-nx-half {
  transform: translateX(-50%);
}

.trf-n-half {
  transform: translate(-50%, -50%);
}

.trf-x-quarter-ny-half {
  transform: translate(25%, -50%);
}


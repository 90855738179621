.list-unstyled {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.outline-none {
  outline: none;
}

.border-circle {
  border-radius: 50%;
}

.border-top {
  border-top: 1px solid var(--muted-100);
}

.border-bottom:not(:last-child) {
  border-bottom: 1px solid var(--muted-100);
}

.border-bottom-with-last {
  border-bottom: 1px solid var(--muted-100);
}

.tap-none {
  -webkit-tap-highlight-color: transparent;
}

.pointer {
  cursor: pointer;
}

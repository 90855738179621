.navigation {
  z-index: 2;
  width: 100%;

  background-color: var(--light);
  box-shadow: 0px -8px 16px rgba(110, 120, 130, 0.12);
}

.navigation-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(5.625rem, 1fr));
}

.navigation-height {
  height: var(--height-navigation);
}

.navigation-height-ios {
  height: calc(var(--height-navigation) + var(--safe-area-inset-bottom));
  padding-bottom: var(--safe-area-inset-bottom);
}

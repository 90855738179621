$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * .25),
      2: ($spacer * .5),
      3: ($spacer * .75),
      4: $spacer,
      5: ($spacer * 1.5),
      6: ($spacer * 2),
      7: ($spacer * 2.5),
      8: ($spacer * 3),
      9: ($spacer * 3.5)
    ),
    $spacers
);

.tabs {
  height: var(--height-tabs);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7.5rem, 1fr));
  border-bottom: 1px solid var(--muted-100);
}

.tab {
  transition: all var(--transition-time-base);

  &.tab--active {
    box-shadow: inset 0px -3px 0px var(--brand-500);
  }
}

.icon {
  &.icon {
    &--base {
      width: 1.5rem;
      height: 1.5rem;
    }

    &--sm {
      width: 1rem;
      height: 1rem;
    }

    &--md {
      width: 2rem;
      height: 2rem;
    }

    &--xl {
      width: 2.5rem;
      height: 2.5rem;
    }

    &--xxl {
      width: 3rem;
      height: 3rem;
    }

    &--xxxl {
      width: 3.5rem;
      height: 3.5rem;
    }

    &--square,
    &--round {
      padding: 4px;
      border: 1px solid var(--text-color);
    }

    &--round {
      border-radius: 50%;
    }
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

@mixin make-text-and-bg-colors($colors) {
  @each $color in $colors {
    .text-#{$color} {
      color: var(--#{$color}-500);
    }

    .bg-#{$color} {
      background-color: var(--#{$color}-500);
    }

    a[href].text-#{$color} {
      & svg {
        fill: var(--#{$color}-500);
      }

      &:hover,
      &:focus,
      &:active {
        color: var(--#{$color}-500);

        svg {
          fill: var(--#{$color}-500);
        }
      }
    }
  }
}

@mixin make-font-weight($weights) {
  @each $weight in $weights {
    .fw-#{$weight} {
      font-weight: var(--weight-#{$weight});
    }
  }
}

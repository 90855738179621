.notifications {
  z-index: 10;
}

.notification {
  &.notification {
    width: 20rem;
    border-radius: var(--border-radius-sm);

    &--basic,
    &--success {
      box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.22), 0px 24px 24px rgba(0, 0, 0, 0.3);
    }

    &--success {
      box-shadow: 0px 0px 24px rgba(51, 51, 51, 0.2), 0px 24px 24px rgba(51, 51, 51, 0.2);
    }
  }

  &--confirm {
    &__submit {
      max-width: 10rem;
    }
  }

  // animation
  &--enter {
    opacity: 0;
  }

  &--enter-active {
    opacity: 1;
    transition: opacity var(--transition-time-fast);
  }

  &--exit {
    opacity: 1;
  }

  &--exit-active {
    opacity: 0;
    transition: opacity var(--transition-time-base);
  }
}

.label-circle {
  &.label-circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;

    &--opacity {
      opacity: 0.2;
    }
  }
}

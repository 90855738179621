.react-select {
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
}

.react-select .select__control {
  border-radius: 0;
  border-bottom: none;
  box-shadow: none;
  min-height: var(--height-input-base);
  cursor: pointer;

  &--is-disabled {
    color: var(--muted-350);
    -webkit-text-fill-color: var(--muted-350);
    background-color: var(--muted-50);
    border-color: var(--muted-200);
    cursor: not-allowed;
    pointer-events: auto;
  }

  &--is-disabled:hover {
    border-color: var(--muted-200);
  }

  .select__value-container .select__placeholder {
    color: var(--muted-300);
  }
}

.react-select .select__control .select__indicator {
  color: var(--muted-300);

  svg {
    transition: fill ease var(--transition-time-base);
    fill: var(--muted-300);
  }
}

.react-select .select__control:not(.select__control--is-disabled):hover .select__indicator,
.react-select .select__control:not(.select__control--is-disabled):focus-within .select__indicator {
  color: var(--muted-500);

  svg {
    fill: var(--muted-500);
  }
}

.react-select .select__control,
.select__menu {
  transition: ease var(--transition-time-base);
  border: 1px solid var(--muted-200);
  border-radius: var(--border-radius-base) !important;
}

.react-select.select_error .select__control {
  border: 1px solid var(--danger-500);
}

.react-select:not(.select_error) .select__control:not(.select__control--is-disabled):hover,
.select__menu:hover {
  box-shadow: initial;
  border-color: var(--brand-500);
}

.react-select:not(.select_error) .select__control:not(.select__control--is-disabled):focus-within {
  border-color: var(--brand-500);
  box-shadow: inset 0 0 0 1px var(--brand-500);
}

.select__menu:focus {
  box-shadow: initial;
  border-color: var(--brand-500);
}

.react-select .select__indicator {
  padding: 0.3125rem;
  height: 100%;
  place-items: center;
}

.react-select .select__control--menu-is-open .select__indicator {
  background: none;
  border-left: none;
}

.react-select .select__indicator-separator {
  display: none;
}

.select__menu {
  margin: 0 !important;
  margin-top: 1px !important;
  box-shadow: none !important;
  z-index: 2 !important;
}

.dropdown-menu .select__menu {
  position: relative;
}

.range .select__menu {
  position: absolute;
}

.select__menu-list {
  border: none !important;
  padding: 0 !important;
}

.select__option {
  display: flex !important;
  align-items: center;
  background: none !important;
  min-height: var(--height-input-base);
  vertical-align: middle;
}

.select__option--is-selected {
  background: var(--brand-300) !important;
  color: var(--light) !important;
}

.select__option--is-focused {
  background: var(--brand-500) !important;
  color: var(--light) !important;
}

.select__option:hover {
  cursor: pointer;
  background: var(--brand-500) !important;
  color: var(--light) !important;
}

.react-select .select__multi-value__label {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.react-select .select__value-container {
  padding-left: 0.75rem;
}

.react-select.select_with_left_icon .select__value-container {
  padding-left: 1.5625rem;
}

.skeleton-loader {
  &.skeleton-loader {
    z-index: 5;

    &--enter {
      opacity: 1;
    }

    &--enter-active {
      opacity: 1;
      transition: opacity var(--transition-time-base);
    }

    &--exit {
      opacity: 1;
    }

    &--exit-active {
      opacity: 0;
      transition: opacity var(--transition-time-base);
    }
  }
}

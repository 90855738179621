.overflow-none {
  overflow: hidden;
}

.overflow-auto {
  overflow: auto;
}

.overflow-y-scroll {
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

@import '../mixins/text-and-colors';

// colors to text, links and backgrounds
.text-light {
  color: var(--light);
}

.text-color {
  color: var(--text-color);
}

.text-dark {
  color: var(--dark);
}

.bg-light {
  background-color: var(--light);
}

.bg-transparent-dark {
  background: rgba(0, 0, 0, 0.5);
}

.bg-muted-25 {
  background-color: var(--muted-25);
}

.bg-muted-50 {
  background-color: var(--muted-50);
}

.bg-muted-75 {
  background-color: var(--muted-75);
}

.bg-muted-200 {
  background-color: var(--muted-200);
}

@include make-text-and-bg-colors($colors);

// font-weights
@include make-font-weight($weights);

// positions
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-top {
  vertical-align: top;
}

.text-middle {
  vertical-align: middle;
}

.text-bottom {
  vertical-align: bottom;
}

// styles
.text-nowrap {
  white-space: nowrap;
}

.text-no-letterspacing {
  letter-spacing: 0;
}

.text-uppercase {
  text-transform: uppercase;
}

// tags
h1, .h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

h2, .h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h3, .h3 {
  font-size: 1rem;
  line-height: 1.5rem;
}

h4, .h4 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

// fonts
.font-xxs {
  font-size: 0.625rem;
  line-height: 1rem;
}

.font-xs {
  font-size: 0.6875rem;
  line-height: 1rem;
}

.font-sm {
  font-size: 0.75rem;
  line-height: 1rem;
}

.font-base {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-md {
  font-size: 1rem;
  line-height: 1.5rem;
}

.font-lg {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.ripple-effect {
  border-radius: inherit;

  .ripple-effect__item {
    transform: scale(0);
    border-radius: 100%;
    opacity: 0.25;
    animation-name: ripple;
  }
}

@keyframes ripple {
  to {
    opacity: 0;
    transform: scale(2);
  }
}

.message {
  border-radius: var(--border-radius-xl);

  &.message {
    &--selected {
      background-color: var(--brand-500);
    }

    &--selected,
    &--selected span,
    &--selected footer {
      color: var(--light);
    }

    &--unread {
      background: linear-gradient(0deg, rgba(0, 118, 136, 0.1), rgba(0, 118, 136, 0.1)), var(--light);
    }
  }
}

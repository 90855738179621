@mixin make-spacings() {
  // Margins and Paddings
  @each $prop, $abbrev in (margin: m, padding: p) {
    @each $size, $length in $spacers {
      .#{$abbrev}-#{$size} {
        #{$prop}: $length;
      }
      .#{$abbrev}t-#{$size},
      .#{$abbrev}y-#{$size} {
        #{$prop}-top: $length;
      }
      .#{$abbrev}r-#{$size},
      .#{$abbrev}x-#{$size} {
        #{$prop}-right: $length;
      }
      .#{$abbrev}b-#{$size},
      .#{$abbrev}y-#{$size} {
        #{$prop}-bottom: $length;
      }
      .#{$abbrev}l-#{$size},
      .#{$abbrev}x-#{$size} {
        #{$prop}-left: $length;
      }
    }
  }

  // Negative margins (e.g., where `.mb-n1` is negative version of `.mb-1`)
  @each $size, $length in $spacers {
    @if $size != 0 {
      .m-n#{$size} {
        margin: -$length;
      }
      .mt-n#{$size},
      .my-n#{$size} {
        margin-top: -$length;
      }
      .mr-n#{$size},
      .mx-n#{$size} {
        margin-right: -$length;
      }
      .mb-n#{$size},
      .my-n#{$size} {
        margin-bottom: -$length;
      }
      .ml-n#{$size},
      .mx-n#{$size} {
        margin-left: -$length;
      }
    }
  }

  // Top, right, bottom, left positions for absolute elements
  @each $prop, $abbrev in (position: a) {
    @each $size, $length in $spacers {
      .#{$abbrev}t-#{$size},
      .#{$abbrev}tl-#{$size},
      .#{$abbrev}tr-#{$size} {
        top: $length;
      }
      .#{$abbrev}r-#{$size},
      .#{$abbrev}tr-#{$size},
      .#{$abbrev}br-#{$size} {
        right: $length;
      }
      .#{$abbrev}b-#{$size},
      .#{$abbrev}bl-#{$size},
      .#{$abbrev}br-#{$size} {
        bottom: $length;
      }
      .#{$abbrev}l-#{$size},
      .#{$abbrev}tl-#{$size},
      .#{$abbrev}bl-#{$size} {
        left: $length;
      }
    }
  }

  // Negative top, right, bottom, left positions for absolute elements (e.g., where `.ab-n1` is negative version of `.ab-1`)
  @each $prop, $abbrev in (position: a) {
    @each $size, $length in $spacers {
      @if $size != 0 {
        .#{$abbrev}t-n#{$size},
        .#{$abbrev}tl-n#{$size},
        .#{$abbrev}tr-n#{$size} {
          top: -$length;
        }
        .#{$abbrev}r-n#{$size},
        .#{$abbrev}tr-n#{$size},
        .#{$abbrev}tl-n#{$size} {
          right: -$length;
        }
        .#{$abbrev}b-n#{$size},
        .#{$abbrev}bl-n#{$size},
        .#{$abbrev}br-n#{$size} {
          bottom: -$length;
        }
        .#{$abbrev}l-n#{$size},
        .#{$abbrev}tl-n#{$size},
        .a#{$abbrev}bl-n#{$size} {
          left: -$length;
        }
      }
    }
  }
}

// Общие стили для оберток элементов, у которых есть иконка внутри: select, datepicker, input (его производные)
.element_wrapper {
  transition: ease var(--transition-time-base);
}

.element_wrapper:hover,
.element_wrapper:focus-within {
  border-color: var(--brand-500);
}

.element_wrapper.element_wrapper_error,
.element_wrapper.element_wrapper_error.element_wrapper_disabled {
  border-color: var(--danger-500);
}

.element_wrapper.element_wrapper_disabled {
  cursor: not-allowed;
  border-color: var(--muted-200);
}

.element_wrapper_icon {
  top: 50%;
  transform: translateY(-50%);
}

.element_wrapper .element_wrapper_icon svg,
.element_wrapper.element_wrapper_disabled:hover .element_wrapper_icon svg {
  fill: var(--muted-300);
  transition: fill ease var(--transition-time-base);
}

.element_wrapper:hover .element_wrapper_icon svg,
.element_wrapper:focus-within .element_wrapper_icon svg {
  fill: var(--brand-500);
}

.element_wrapper.element_wrapper_error .element_wrapper_icon svg,
.element_wrapper.select_error .element_wrapper_icon svg,
.element_wrapper.element_wrapper_error:hover .element_wrapper_icon svg,
.element_wrapper.select_error:hover .element_wrapper_icon svg {
  fill: var(--danger-500);
}

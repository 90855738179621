.header {
  z-index: 2;

  background-color: var(--light);
}

.header-shadow {
  box-shadow: 0px 8px 16px rgba(110, 120, 130, 0.12);
}

.header-height {
  height: var(--height-header);
}

.header-height-ios {
  height: calc(var(--height-header) + var(--safe-area-inset-top));
  padding-top: var(--safe-area-inset-top);
}

.spinner {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;

  svg {
    background: none;
    shape-rendering: auto;
  }

  &.spinner {
    // Темы
    &--danger svg {
      fill: var(--danger-500);
    }

    &--muted svg {
      fill: var(--muted-500);
    }

    &--info svg {
      fill: var(--info-500);
    }

    &--brand svg {
      fill: var(--brand-500);
    }

    &--primary svg {
      fill: var(--primary-500);
    }

    &--success svg {
      fill: var(--success-500);
    }

    &--warning svg {
      fill: var(--warning-500);
    }

    // Верхнее положение в зависимости от контента
    &--content {
      z-index: 2;

      &-tabs {
        top: 4.5rem
      }

      // В зависимости от длины названия документа и вывода этого названия построчно, учитывается до 4х строк:
      &-doc1 {
        top: 8rem;
      }

      &-doc2 {
        top: 10rem;
      }

      &-doc3 {
        top: 12rem;
      }

      &-doc4 {
        top: 14rem;
      }

      &-file1 {
        top: 7rem;
      }

      &-file2 {
        top: 9rem;
      }

      &-file3 {
        top: 11rem;
      }

      &-file4 {
        top: 13rem;
      }
    }
  }
}

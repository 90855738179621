.set-pin-code {
  &.set-pin-code {
    height: 100vh;

    &--ios {
      padding-top: var(--safe-area-inset-top);
      padding-bottom: var(--safe-area-inset-bottom);
    }
  }

  .set-pin-code__labels {
    height: 5rem;
  }
}

.layout {
  &.layout {
    &--all {
      top: var(--height-header);
    }

    &--ios {
      top: calc(var(--height-header) + var(--safe-area-inset-top))
    }
  }
}

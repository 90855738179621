.skeleton {
  &.skeleton {
    svg {
      display: block;
      width: 100%;
      height: 100%;
      fill: var(--muted-100);
    }

    // heights
    &--date-xs,
    &--field-label,
    &--field-value,
    &--label,
    &--link-doc,
    &--nav-label,
    &--status-icon,
    &--time-xs,
    &--usera-action,
    &--username {
      height: 1rem;
    }

    &--block-button,
    &--contractor,
    &--date-base,
    &--list-item-title,
    &--message-text,
    &--some-text,
    &--status-label,
    &--tab,
    &--time-base {
      height: 1.25rem;
    }

    &--contractor,
    &--icon,
    &--nav-icon {
      height: 1.5rem;
    }

    &--doc-title,
    &--header-title {
      height: 1.75rem;
    }

    &--pin-code-labels {
      height: 5rem;
    }

    &--pin-code-keyboard {
      height: 18rem;
    }

    // widths
    &--badge,
    &--status-icon {
      width: 1rem;
    }

    &--icon,
    &--messages,
    &--nav-icon {
      width: 1.5rem;
    }

    &--time-xs {
      width: 2rem;
    }

    &--time-base {
      width: 2.5rem;
    }

    &--date-xs,
    &--field-value {
      width: 3.5rem;
    }

    &--date-base {
      width: 4.5rem;
    }

    &--nav-label {
      width: 4.5rem;
    }

    &--field-label,
    &--tab,
    &--username {
      width: 5rem;
    }

    &--label {
      width: 6rem;
    }

    &--status-label {
      width: 7.5rem;
    }

    &--block-button,
    &--header-title,
    &--some-text,
    &--user-action {
      width: 10rem;
    }

    &--contractor,
    &--link-doc {
      width: 12.5rem;
    }

    &--message-text {
      width: 18.5rem;
    }

    &--doc-title,
    &--list-item-title {
      width: 20rem;
    }
  }
}
